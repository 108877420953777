import React, { useEffect, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';

type PDFListItem = {
  id: number;
  pdfName: string;
  isExpanded: boolean;
  pages: string[];
};

interface SourceListProps {
    listItemsData: any;
    handlePdfOpen: (fileName: string, page: string) => void;
    fontSize?: string;
}

export default function SourceList({ listItemsData, handlePdfOpen, fontSize }: SourceListProps) {
  const [listItems, setListItems] = useState<PDFListItem[]>([]);

  useEffect(() => {
    if(listItemsData.file_names) {
      const map = new Map<string, Set<string>>();

      listItemsData.file_names.forEach((fileName: string, index: number) => {
        if (!fileName) return;
        
        const pageNumber = listItemsData.page_numbers[index];
        if (map.has(fileName)) {
          map.get(fileName)!.add(pageNumber);
        } else {
          map.set(fileName, new Set([pageNumber]));
        }
      });

      const newItems = Array.from(map.entries()).map(([fileName, pages], index) => {
        const existingItem = listItems.find(item => item.pdfName === fileName);
        return {
          id: index + 1,
          pdfName: fileName,
          isExpanded: existingItem ? existingItem.isExpanded : false,
          pages: Array.from(pages),
        };
      });

      setListItems(newItems);
    }
  }, [listItemsData]);

  const handleClick = (id: number) => {
    setListItems(prevState =>
      prevState.map(item =>
        item.id === id ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
  };

  return (
    <List
      sx={{ 
        width: '100%', 
        bgcolor: 'background.paper', 
        background: 'transparent',
        '& .MuiListItemText-primary': {
          fontSize: fontSize || '0.9rem'
        }
      }}
      component="nav"
    >
      {listItems.map((item, index) => (
        <Box key={item.id}>
          <ListItemButton onClick={() => handleClick(item.id)}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <ListItemText 
                primary={`#${index + 1}`} 
                sx={{
                  mr: 1,
                  '& .MuiListItemText-primary': {
                    fontSize: fontSize || '0.9rem'
                  }
                }}
              />
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
            </Box>
            <ListItemText 
              primary={item.pdfName}
              sx={{
                '& .MuiListItemText-primary': {
                  fontSize: fontSize || '0.9rem'
                }
              }}
            />
            {item.isExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={item.isExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.pages.map((page, index) => (
                <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => handlePdfOpen(item.pdfName, page)}>
                  <ListItemIcon>
                    <FindInPageIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`Page ${page}`}
                    sx={{
                      '& .MuiListItemText-primary': {
                        fontSize: fontSize || '0.9rem'
                      }
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Box>
      ))}
    </List>
  );
}
