import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Link,
    CircularProgress,
    Tooltip,
    IconButton,
    Drawer,
    Paper,
    useMediaQuery
} from '@mui/material';
import { PDFDialog } from './components/PDFDialog';
import { Filter } from './components/Filter';
import { useChat } from 'contexts/ChatContext';
import SourceList from './components/SourceList';
import { marked } from 'marked';
import Incognito from './components/Incognito';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import theme from 'theme';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import icon from 'assets/aas-icon.png';

const ChatBot = (): JSX.Element => {
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState({ fileName: '', page: '' });
    const [userInput, setUserInput] = useState<string | null>(null);
    const [showFormattingMessage, setShowFormattingMessage] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false); // user feedback form
    const [displayInput, setDisplayInput] = useState<{ key: string, value: string }[]>([]);
    const { currentSessionId } = useChat();
    let timer: NodeJS.Timeout;
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));



    const {
        handleSubmit,
        inputText,
        setInputText,
        isLoading,
        history,
        bottomRef,
        saveHistoryBool,
    } = useChat();

    useEffect(() => {
        if (isLoading) {
            timer = setTimeout(() => {
                setShowFormattingMessage(true);
            }, 10000);
        } else {
            setShowFormattingMessage(false);
        }

        return () => clearTimeout(timer);
    }, [isLoading]);

    const handleDrawerToggle = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };
    const addInput = (key: string, value: string) => {
        setDisplayInput((prevArray) => [...prevArray, { key, value }]);
    };
    const getMostRecentInput = (key: string) => {
        const foundPair = [...displayInput].reverse().find(pair => pair.key === key);
        return foundPair ? foundPair.value : 'Key not found';
    };


    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addInput(currentSessionId, inputText);
            setInputText('');
            handleSubmit();
        }
    };

    const createMarkUp = (markdown: string): { __html: string } => {
        const htmlContent = marked(markdown);
        if (typeof htmlContent !== 'string') {
            throw new Error("Marked did not return a string. Check the usage.");
        }
        return { __html: htmlContent };
    };



    const handlePdfOpen = (fileName: string, page: string) => {
        console.log('PAGE: ', page);
        setSelectedPdfUrl({ fileName, page });
        setPdfDialogOpen(true);
    };

    const handlePdfClose = () => {
        setPdfDialogOpen(false);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '75vh',
            position: 'relative',
        }}>
            <PDFDialog
                pdfData={selectedPdfUrl}
                open={pdfDialogOpen}
                handleClose={handlePdfClose}
            />

            {/* Chat history */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1,
                pb: !saveHistoryBool ? '180px' : '130px',
            }}>
                <Box sx={{
                    width: isMobile ? '400px': '1100px',
                    margin: '0 auto',
                }}>
                    {/* History content remains the same */}
                    {history.map((entry, index) => (
                        <Box key={index} sx={{ p: 2 }}>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'flex-end',
                                mb: 2,
                                width: '100%',
                                ml: isMobile ? '-40px' : '0px'
                            }}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: theme.palette.primary.main,
                                        padding: '10px 15px',
                                        borderRadius: '20px',
                                        maxWidth: '80%',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <Typography variant="body1" sx={{
                                        fontSize: isMobile ? '0.95rem' : '1rem'
                                    }}>
                                        {entry.user}
                                    </Typography>
                                </Paper>
                            </Box>
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'flex-start',
                                gap: 3,
                                maxWidth: '80%',
                                ml: isMobile ? 0:1,
                            }}>
                                <SmartToyIcon sx={{
                                    color: theme.palette.primary.main,
                                    mt: 2,
                                    mr: isMobile ? '-10px' : '0px', // Adjusted margin for mobile
                                    fontSize: '25px',
                                }} />
                                <Box>
                                    <Typography variant="body1" component="span" sx={{
                                        fontSize: isMobile ? '0.95rem' : '1rem'
                                    }}>
                                        <span dangerouslySetInnerHTML={createMarkUp(entry.darby.answer)} />
                                        {entry.darby.file_names && entry.darby.file_names.some(fileName => fileName.trim() !== '') && (
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                backgroundColor: 'transparent',
                                                fontSize: isMobile ? '0.95rem' : '1rem'
                                            }}>
                                                <br></br>
                                                Sources:
                                            </Typography>
                                        )}
                                        <SourceList
                                            listItemsData={entry.darby}
                                            handlePdfOpen={handlePdfOpen}
                                            fontSize={isMobile ? '0.95rem' : '1rem'}
                                        />
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}

                    {isLoading && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
                             <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'flex-end',
                                mb: 0,
                                pr: 0, // Remove any right padding                                
                            }}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: theme.palette.primary.main,
                                        padding: '10px 15px',
                                        borderRadius: '20px',
                                        maxWidth: '80%',
                                        wordWrap: 'break-word',
                                        mr: isMobile ? 7 : 2,
                                    }}
                                >
                                    <Typography variant="body1">
                                        {getMostRecentInput(currentSessionId)}
                                    </Typography>
                                </Paper>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: -5, ml: isMobile ? -7 : 0 }}>
                                <CircularProgress />
                                <Typography variant="body1" sx={{ ml: 2 }}>
                                    {showFormattingMessage ? "Formatting response..." : "Thinking..."}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box ref={bottomRef} sx={{ height: isLoading ? '64px' : '0px' }}></Box>
                </Box>
            </Box>

            {/* White overlay with footer and incognito mode */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: !saveHistoryBool ? '195px' : '100px',
                    backgroundColor: 'white',
                    zIndex: 998,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    pb: 2,
                }}
            >
                {!saveHistoryBool && (
                    <Box sx={{
                        position: 'absolute',
                        top: '40px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 1000,
                    }}>
                        <Incognito />
                    </Box>
                )}
                <Box
                    component="img"
                    src={icon}
                    sx={{
                        width: '30px',
                        height: '30px',
                        mb: 1,
                    }}
                />
                <Typography variant="body2" color={theme.palette.primary.main} align="center" sx={{ fontSize: isMobile ? '0.8rem' : '0.9rem' }}>
                    {'Copyright © '}
                    <Link color="inherit" href="https://agingaircraftsolutions.com">
                        Aging Aircraft Solutions
                    </Link>{' '}
                    {new Date().getFullYear()}.
                </Typography>
            </Box>

            {/* Input box */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: !saveHistoryBool ? '150px' : '100px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: isMobile ? '90%' : '1100px', // Adjusted width for mobile
                    backgroundColor: 'white',
                    zIndex: 999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <TextField
                    placeholder="Message DARBy"
                    variant="outlined"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    sx={{
                        width: '100%',
                        maxWidth: '1100px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '25px',
                            height: '45px',
                            paddingRight: '45px',
                        },
                        '& .MuiInputLabel-root': {
                            display: 'none'
                        },
                        '& .MuiOutlinedInput-input': {
                            height: '45px',
                            padding: '0 16px',
                            display: 'flex',
                            alignItems: 'center',
                        },
                    }}
                    autoComplete="off"
                />
                <IconButton
                    onClick={() => {
                        addInput(currentSessionId, inputText);
                        setInputText('');
                        handleSubmit();
                    }}
                    disabled={!inputText.trim()}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            color: 'rgba(0, 0, 0, 0.26)',
                        },
                        width: '32px',
                        height: '32px',
                        zIndex: 1,
                    }}
                >
                    <ArrowUpwardIcon sx={{ fontSize: '20px' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default ChatBot;