import React, { useState } from 'react';
import MainLayout from 'layouts/MainLayout';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useTheme,
} from '@mui/material';
import { submitSupportRequest } from 'core/api/functions';
import { useNavigate } from 'react-router-dom';

const SupportRequest = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();

    const issueTypes = [
        {
            value: 'technical',
            label: 'Technical Issue'
        },
        {
            value: 'account',
            label: 'Account Issue'
        },
        {
            value: 'bug',
            label: 'Application Bug'
        },
        {
            value: 'other',
            label: 'Other'
        }
    ];

    const [requestDetails, setRequestDetails] = useState({
        issueType: '',
        description: ''
    });

    const handleChange = (event: any) => {
        setRequestDetails({
            ...requestDetails,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Extracting issueType and description for clarity
        const { issueType, description } = requestDetails;

        const issueLabel = issueTypes.find(type => type.value === issueType)?.label || '';


        try {
            const response = await submitSupportRequest(issueLabel, description);
            setOpen(true);

        } catch (error) {
            console.error('Failed to submit support request:', error);
        }
    };

    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setOpen(false);
        setRequestDetails({
            issueType: '',
            description: ''
        });
    }

    const SuccessSentDialog = () => {
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Support Request Sent</DialogTitle>
                <DialogContent>
                    <Typography>
                        Your support request has been sent. We will get back to you as soon as possible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

            </Dialog>
        )
    }



    return (
        <MainLayout>
            <SuccessSentDialog />
            <Container component="main" maxWidth="sm">
                <Box sx={{ 
                    marginTop: 8, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        width: { xs: '90%', sm: '100%' },
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        border: `4px solid ${theme.palette.primary.main}`,
                        padding: { xs: '20px', sm: '40px' },
                        mb: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography 
                            component="h1" 
                            variant="h4" 
                            textAlign="center" 
                            sx={{
                                fontWeight: 'bold', 
                                color: theme.palette.primary.main,
                                fontSize: { xs: '1.5rem', sm: '2.125rem' },
                            }}
                        >
                            Support Request
                        </Typography>
                        <Typography 
                            component="p" 
                            textAlign="center" 
                            sx={{ 
                                mt: 2, 
                                color: theme.palette.primary.main,
                                fontSize: { xs: '0.9rem', sm: '1rem' },
                            }}
                        >
                            Please fill out the form below and we will get back to you as soon as possible. This message will be sent to the relevant support team.
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                            <FormControl fullWidth sx={{ 
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '10px',
                                    borderColor: theme.palette.primary.main,
                                    backgroundColor: 'white',
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: '2px',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    display: 'none',
                                },
                                '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                                    color: requestDetails.issueType ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.38)',
                                },
                            }}>
                                <Select
                                    displayEmpty
                                    name="issueType"
                                    value={requestDetails.issueType}
                                    onChange={handleChange}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Issue Type</span>;
                                        }
                                        return selected;
                                    }}
                                >
                                    {issueTypes.map((issueType) => (
                                        <MenuItem key={issueType.value} value={issueType.value}>
                                            {issueType.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="description"
                                placeholder="Description"
                                type="text"
                                id="description"
                                multiline
                                rows={6}
                                value={requestDetails.description}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        borderColor: theme.palette.primary.main,
                                        backgroundColor: 'white',
                                        '& fieldset': {
                                            borderColor: theme.palette.primary.main,
                                            borderWidth: '2px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: theme.palette.primary.main,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.primary.main,
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        display: 'none',
                                    },
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ 
                                    mt: 3, 
                                    mb: 2,
                                    color: 'white',
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '2px',
                                    borderRadius: '25px',
                                    backgroundColor: 'theme.palette.primary.main',
                                    '&:hover': {
                                        backgroundColor: 'theme.palette.primary.main',
                                        color: 'red',
                                        borderColor: 'red',
                                        borderWidth: '2px',
                                    }
                                }}
                            >
                                Submit Request
                            </Button>

                            <Box sx={{ display: 'flex', justifyContent: 'center', mb:-2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => navigate('/')}
                                    sx={{ 
                                        color: theme.palette.primary.main,
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: '2px',
                                        borderRadius: '25px',
                                        width: '200px',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            color: 'red',
                                            borderColor: 'red',
                                            borderWidth: '2px',
                                        }
                                    }}
                                >
                                    Return to Home
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MainLayout>
    );
}

export default SupportRequest;
