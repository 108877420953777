import React, { useEffect, useState } from 'react';
import { Typography, Slide, Box, Drawer, styled, Button, IconButton, Divider, useTheme, Tooltip, CssBaseline, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import { db } from "core/config/firebase";
import { getDocs, collection, doc, getDoc, updateDoc, writeBatch, deleteDoc } from "firebase/firestore";
import { getCurrentUserId } from "core/api/api";
import { useAuth } from "contexts/AuthContext";
import { BookOutlined, Create, Campaign, Settings } from '@mui/icons-material';
import { useChat } from 'contexts/ChatContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CustomInstructions from './components/CustomInstructions';
import LinearProgress from '@mui/material/LinearProgress';
import KnowledgeBase from '../KnowledgeBase';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { format } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ChatBot from '../ChatBot';
import { logoLight } from 'assets';
import { ToggleHistory } from '../ChatBot/components/ToggleHistory';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { FeedbackForm } from './components/FeedbackForm';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useNavigate } from 'react-router-dom';
import SupportIcon from '@mui/icons-material/Support';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const HistorySidebar = () => {
  const [docNames, setDocNames] = useState<string[]>([]);
  const { userInfo } = useAuth();
  const uid = getCurrentUserId();
  const theme = useTheme();

  const { chatSessionList, switchChat, initializeNewSession, createNewChat, firstChatLoading, currentSessionId } = useChat();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [instructionText, setInstructionText] = useState<string>("");
  const [isOpen, setOpen] = useState(false);
  const [chatTitles, setChatTitles] = useState<{ [key: string]: string | null }>({});
  const [toolsAnchorEl, setToolsAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseAnchorEl, setKnowledgeBaseAnchorEl] = useState<null | HTMLElement>(null);
  const [knowledgeBaseOpen, setKnowledgeBaseOpen] = useState(false);
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('additionalTools');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatIdToDelete, setChatIdToDelete] = useState<string | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleToolsClick = () => {
    setSelectedMenu('additionalTools')
    setToolsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setToolsDialogOpen(false);
  };

  const handleKnowledgeBaseOpen = () => {
    setKnowledgeBaseOpen(true);
  };

  const handleKnowledgeBaseClose = () => {
    setKnowledgeBaseOpen(false);
  };

  const handleClose = () => {
    setToolsAnchorEl(null);
    setKnowledgeBaseAnchorEl(null);
  };


  // always grab the most recent values from firestore
  const handleDialogOpen = async () => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const latestInstructions = userDoc.data().chat_custom_instructions || "";
        setInstructionText(latestInstructions.replace(/^"|"$/g, ""));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setDialogOpen(true);
  };

  useEffect(() => {
    const fetchChatTitles = async () => {
      if (!uid) return;

      const titles: { [key: string]: string | null } = {};

      for (const session of chatSessionList) {
        const chatHistoryRef = doc(db, `users/${uid}/chat_history/${session.id}`);
        await getDoc(chatHistoryRef)
          .then((docData) => {
            if (docData.exists()) {
              const messages = docData.data()["messages"] || [];
              const title = messages[1]?.data?.chat_title;
              titles[session.id] = title || null;
            }
          })
          .catch((error) => {
            console.error("Error fetching chat title:", error);
          });
      }
      setChatTitles(titles);
    };

    fetchChatTitles();
  }, [uid, chatSessionList, db]);


  useEffect(() => {
    // Remove quotes from value
    const userCustomMessage = userInfo?.chat_custom_instructions || "";
    setInstructionText(userCustomMessage.replace(/^"|"$/g, ""));
  }, [userInfo]);

  // set new chat_custom_instructions in firestore
  const setInstructions = async (newInstructions: string) => {
    if (!uid) {
      console.error("User ID is not defined");
      return;
    }

    try {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        chat_custom_instructions: newInstructions,
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSidebarHistoryToggle = () => {
    setOpen(prevState => !prevState);
  };

  const handleSidebarHistoryOpen = () => {
    setOpen(true);
  };

  const handleSidebarHistoryClose = () => {
    setOpen(false);
  };

  const handleInitializeNewSession = async () => {
    setOpen(false);
    await initializeNewSession();
  };

  const handleSwitchChat = async (sessionId: string) => {
    await switchChat(sessionId);
  };

  const deleteSingleChat = async (chatId: string) => {
    if (uid) {
      try {
        const userDocRef = doc(db, 'users', uid);
        const chatHistoryRef = collection(userDocRef, 'chat_history');

        const snapshot = await getDocs(chatHistoryRef);
        const chatCount = snapshot.size;

        const chatDocRef = doc(userDocRef, 'chat_history', chatId);

        await deleteDoc(chatDocRef);
        console.log('Chat deleted successfully.');

        if (chatId === currentSessionId) {
          handleInitializeNewSession();
        }
      } catch (error) {
        console.error('Error deleting chat: ', error);
      }
    }
  };



  const handleDeleteChat = (chatId: string) => {
    setChatIdToDelete(chatId)
    setOpenDeleteDialog(true);
  }
  const handleConfirmDelete = () => {
    if (chatIdToDelete) {
      deleteSingleChat(chatIdToDelete);
    }
    setOpenDeleteDialog(false)
  }
  const handleCancelDelete = () => {
    setChatIdToDelete(null);
    setOpenDeleteDialog(false);
  }

  const drawerWidth = 240;

  const formatTimestamp = (timestamp: string) => {
    const timestampNumber = parseFloat(timestamp);

    if (isNaN(timestampNumber)) {
      return '';
    }

    const date = new Date(timestampNumber);
    return date.toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric',
    }).replace(/(\d+)/, '$1th'); // This adds the 'th' suffix to the day
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: '4px 0 10px -5px rgba(0,0,0,0.2)',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        <DrawerHeader style={{ backgroundColor: '#30242e', display: 'flex', justifyContent: 'space-between' }}>
          <Box
            component="img"
            src={logoLight}
            sx={{ width: "10vw", maxWidth: 70, maxHeight: 70 }}
          />
        </DrawerHeader>
        <Divider />
        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
          <Box sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title="New Chat" placement="right" >
                  <IconButton onClick={createNewChat} >
                    <Create />
                  </IconButton>
                </Tooltip>
              </Box>
              <Tooltip title="Close sidebar" placement='right' sx={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={handleSidebarHistoryClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon color='error' /> : <ChevronRightIcon />}
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
              <Typography variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
                Chat History
              </Typography>
              <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', maxHeight: '70vh' }}>
                {chatSessionList.length > 0 ? (
                  <List>
                    {(() => {
                      // Group chats by date
                      const groupedChats = chatSessionList.reduce((acc: { [key: string]: any[] }, session) => {
                        const date = formatTimestamp(session.date_created);
                        if (!acc[date]) {
                          acc[date] = [];
                        }
                        acc[date].push(session);
                        return acc;
                      }, {});

                      // Sort dates in reverse chronological order
                      return Object.entries(groupedChats).map(([date, sessions]) => (
                        <React.Fragment key={date}>
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              pl: 2, 
                              pt: 1, 
                              pb: 1, 
                              color: theme.palette.primary.main,
                              fontWeight: 'bold',
                            }}
                          >
                            {date}
                          </Typography>
                          {/* Sort sessions within each date by timestamp in reverse order */}
                          {sessions
                            .sort((a, b) => parseFloat(b.date_created) - parseFloat(a.date_created))
                            .map((session, index) => {
                              const isActive = currentSessionId === session.id;
                              const chatTitle = chatTitles[session.id];

                              return chatTitle ? (
                                <ListItem 
                                  disablePadding 
                                  key={session.id}
                                  sx={{ pl: 3 }}
                                >
                                  <ListItemButton
                                    onClick={() => handleSwitchChat(session.id)}
                                    sx={{
                                      backgroundColor: isActive
                                        ? theme.palette.action.selected
                                        : "inherit",
                                      "&:hover": {
                                        backgroundColor: isActive
                                          ? theme.palette.action.selected
                                          : theme.palette.action.hover,
                                      },
                                    }}
                                  >
                                    {firstChatLoading.isLoading && firstChatLoading.index === index ? (
                                      <LinearProgress
                                        sx={{
                                          width: "100%",
                                          color: theme.palette.primary.main,
                                        }}
                                      />
                                    ) : (
                                      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                        <Box>
                                          <ListItemText
                                            primary={chatTitle}
                                            primaryTypographyProps={{ fontSize: '14px' }}
                                          />
                                        </Box>
                                        <Tooltip title="Delete chat">
                                          <IconButton
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleDeleteChat(session.id);
                                            }}
                                            edge="end"
                                            aria-label="delete"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    )}
                                  </ListItemButton>
                                </ListItem>
                              ) : null;
                            })}
                        </React.Fragment>
                      ));
                    })()}
                  </List>
                ) : (
                  <Typography variant="body1" sx={{ ml: 8 }}>No chat history.</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Slide>
        <Divider />
      </Drawer>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={openDeleteDialog} 
        onClose={handleCancelDelete}
        disableScrollLock={true}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '20px',
          }
        }}
      >
        <DialogTitle sx={{ 
          color: 'white', 
          height: '55px', 
          mt: '-5px',
          fontWeight: 'bold',
        }}>
          Confirm Delete Chat
        </DialogTitle>
        <DialogContent sx={{ 
          mt: '20px', 
          mb: '-10px', 
          fontSize: '1.1rem',
          color: 'white',
        }}>
          Are you sure you want to delete this chat?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleCancelDelete();
            }}
            sx={{ 
              color: 'white',
              '&:hover': {
                color: 'red',
                backgroundColor: 'transparent',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleConfirmDelete();
            }}
            sx={{ 
              color: 'white',
              '&:hover': {
                color: 'red',
                backgroundColor: 'transparent',
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Main open={isOpen} sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        marginLeft: isMobile ? '-235px !important': '-445px !important',
        width: '100vw',
        position: 'relative',
        overflowX: 'hidden',
      }}>
        <Box sx={{ 
          width: isMobile ? '40px' : '200px', 
          position: 'fixed',
          top: 60,
          left: isOpen ? drawerWidth : 0,
          padding: isMobile ? '6px' : '16px',
          backgroundColor: 'white',
          zIndex: 1000,
          transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}>
          {!isOpen && (
            <>
              <Tooltip title="Chat History" placement="bottom">
                <IconButton onClick={handleSidebarHistoryToggle} >
                  <BookOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="New Chat" placement="bottom">
                <IconButton onClick={createNewChat} >
                  <Create />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
        <Box sx={{ 
          width: '30%', 
          position: 'fixed', 
          right: '23px', 
          top: '75px', 
          display: 'flex', 
          justifyContent: 'flex-end',
          zIndex: 1000,
        }}>
          {!isOpen && (
            <>
              <Tooltip title="Provide Feedback" placement="bottom">
                <IconButton onClick={handleOpenForm}>
                  <AddCommentIcon />
                </IconButton>
              </Tooltip>

              <Dialog 
                open={isFormOpen} 
                onClose={handleCloseForm} 
                maxWidth="sm" 
                fullWidth
                disableScrollLock={true}
              >
                <DialogContent>
                  <FeedbackForm open={isFormOpen} onClose={handleCloseForm}/>
                </DialogContent>
              </Dialog>
            </>
          )}
        </Box>

        <Box sx={{ 
          width: '100%',
          maxWidth: isMobile ? '100vw':'900px',
          margin: '0 auto',
          mt: '60px',
          ...(isMobile && {
            overflowX: 'hidden',
            paddingLeft: '0px',
            paddingRight: '0px',
          })
        }}> 
          <CustomInstructions
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            instructionText={instructionText}
            setInstructionText={setInstructionText}
            setInstructions={setInstructions}
          />
          <KnowledgeBase open={knowledgeBaseOpen} onClose={handleKnowledgeBaseClose} />
          <ChatBot />
        </Box>
      </Main>
    </Box>
  );
};

export default HistorySidebar;